import courseware from './courseware';
import dashboard from './dashboard';
import developer from './developer';
import other from './other';
import instructor from './instructor';
import coursewareAdmin from './courseware-admin';
import { isAllowed } from 'roles';
import { MicrosoftUser } from 'app/types/contexts/microsoft.types';

// ==============================|| MENU ITEMS ||============================== //

/**
 * Create menu items based on the users role
 * If this is updated, ensure the routes reflect the changes!
 */
const createRoleBasedNavigation = (
  microsoftUser: MicrosoftUser
): { items: any[] } => {
  const roles = microsoftUser?.roles;

  /**
   * Create Menu from Application Role
   */
  if (isAllowed(roles, ['developer'])) {
    /**
     * Developer
     */
    return {
      items: [dashboard, coursewareAdmin, developer, other]
      // items: [dashboard, courseware, admin, developer, other]
    };
  } else if (isAllowed(roles, ['admin'])) {
    // Managers
    return {
      items: [dashboard, coursewareAdmin, other]
    };
  } else if (isAllowed(roles, ['designer', 'tm', 'ce'])) {
    // Designers
    return {
      items: [dashboard, coursewareAdmin, other]
    };
  } else if (isAllowed(roles, ['sme'])) {
    // Subject Matter Experts
    return {
      items: [dashboard, courseware, other]
    };
  } else {
    // All staff including instructors
    return {
      items: [dashboard, instructor, other]
    };
  }
};

export default createRoleBasedNavigation;
