import { ReadOutlined } from '@ant-design/icons';

// icons
const icons = {
  ReadOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const courseware = {
  id: 'group-courseware',
  title: 'Courseware',
  type: 'group',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      type: 'item',
      icon: icons.ReadOutlined,
      url: '/courses'
    }
  ]
};

export default courseware;
