import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

// assets
import {
  EnvironmentOutlined,
  IdcardOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons';
import useAuth from 'hooks/useAuth';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const MicrosoftTab = () => {
  const theme = useTheme();
  const { user } = useAuth();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': { minWidth: 32 },
        '& .MuiButtonBase-root.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
          '.MuiListItemIcon-root': { color: theme.palette.primary.main },
          ':hover': { backgroundColor: theme.palette.primary.lighter }
        }
      }}
    >
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary={user?.name} />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
      >
        <ListItemIcon>
          <MailOutlined />
        </ListItemIcon>
        <ListItemText primary={`${user?.email}`.toLowerCase()} />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(event) => handleListItemClick(event, 2)}
      >
        <ListItemIcon>
          <IdcardOutlined />
        </ListItemIcon>
        <ListItemText primary={user?.jobTitle} />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event) => handleListItemClick(event, 3)}
      >
        <ListItemIcon>
          <EnvironmentOutlined />
        </ListItemIcon>
        <ListItemText primary={user?.officeLocation} />
      </ListItemButton>
    </List>
  );
};

export default MicrosoftTab;
