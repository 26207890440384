/* eslint-disable @typescript-eslint/no-unused-vars */
// material-ui
import { useMediaQuery, useTheme } from '@mui/material';

// project import
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/@third-party/SimpleBar';
import { useSelector } from 'store';
import { getEnvironmentVariable } from 'app/service/utils/environment';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Navigation />
      {/* {drawerOpen && !matchDownMD && <NavCard />} */}
      {drawerOpen && (
        <p
          style={{
            position: 'fixed',
            bottom: -10,
            left: 28,
            padding: '10px',
            textAlign: 'center',
            fontSize: '0.75rem',
            color: '#888888'
          }}
        >
          Version {getEnvironmentVariable('REACT_APP_TOOLBOX_VERSION')}
        </p>
      )}
    </SimpleBar>
  );
};

export default DrawerContent;
