import { DashboardOutlined, ReadOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  ReadOutlined
};

const instructor = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      type: 'item',
      icon: icons.ReadOutlined,
      url: '/courses'
    }
  ]
};

export default instructor;
