/**
 * Get Authorization Code from Server
 * This code is not sensitive data as it simply enables an Equella Login
 */
export function getHangarAuthorisationCodeGrant(path: string) {
  const baseUrl = process.env.REACT_APP_HANGAR_BASE_URL;
  const clientId = process.env.REACT_APP_HANGAR_OAUTH_CLIENT;
  const redirectUri = process.env.REACT_APP_HANGAR_OAUTH_REDIRECT;
  if (path)
    return `${baseUrl}/oauth/authorise?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${path}`;
  else
    return `${baseUrl}/oauth/authorise?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
}
