import { axiosRequestJSON } from 'app/axios/axiosRequests';
import { getAuthEndpoint } from 'app/integration/server/endpoints/auth';
import {
  getHangarApiHeadersNoAuth,
  getHangarAuthHeadersCustom
} from 'app/integration/server/headers/headers';

/**
 * ========================================================
 * Aviation Australia API
 * Client library /auth functions
 * ========================================================
 */

// const defaultTimeout = 5000; // 5 seconds
const defaultTimeout = 10000; // 10 seconds
const baseUrl = getAuthEndpoint();

/**
 * Get Hangar access token from auth code
 */
export async function getHangarAccessTokenFromAuthCode(authCode: string) {
  const headers = getHangarApiHeadersNoAuth();
  const url = `${baseUrl}/hangar?authCode=${authCode}`;
  const res = await axiosRequestJSON('GET', url, headers, null, defaultTimeout);
  return res;
}

/**
 * Refresh hangar SSO token via MSAL token
 */
export async function refreshHangarSSOToken(id: string, accessToken: string) {
  const headers = getHangarAuthHeadersCustom(accessToken, {
    'X-Microsoft': id
  });
  const url = `${baseUrl}/sso`;
  const res = await axiosRequestJSON('GET', url, headers, null, defaultTimeout);
  return res;
}

/**
 * Get current microsoft session information
 */
export async function getCurrentAuthSession(
  accessToken: string,
  microsoftId: string
) {
  const headers = getHangarAuthHeadersCustom(accessToken, {
    'X-Microsoft': microsoftId
  });
  const url = `${baseUrl}/session`;
  const res = await axiosRequestJSON('GET', url, headers, null, defaultTimeout);
  return res;
}
