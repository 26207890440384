import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/mantis/auth';
import useHangarAuth from 'hooks/useHangarAuth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn: authenticatedWithMicrosoft } = useAuth();
  const { isLoggedIn: authenticatedWithHangar } = useHangarAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authenticatedWithMicrosoft || !authenticatedWithHangar) {
      /**
       * If user is not authenticated with Microsoft or Hangar, and they go to the login page, redirect to login
       */
      navigate('/login');
    }
  }, [authenticatedWithMicrosoft, authenticatedWithHangar, navigate, location]);

  return children;
};

export default AuthGuard;
