export function isString(variable: any): boolean {
  return typeof variable === 'string' || variable instanceof String;
}

export function removeDuplicatesAndSort(strings: string[]): string[] {
  const uniqueStrings = Array.from(new Set(strings));
  const sortedStrings = uniqueStrings.sort();
  return sortedStrings;
}

export function capitaliseFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeTrailingSlashFromUrl(url: string): string {
  // Check if the URL is just a single slash or does not end with a slash, return as is
  if (url === '/' || !url.endsWith('/')) {
    return url;
  }

  // Remove all trailing slashes
  return url.replace(/\/+$/, '');
}
