import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

// assets
import {
  BellOutlined,
  IssuesCloseOutlined,
  LinkOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import useHangarAuth from 'hooks/useHangarAuth';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const HangarTab = ({ handleLogout }: Props) => {
  const theme = useTheme();

  const { user } = useHangarAuth();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': { minWidth: 32 },
        '& .MuiButtonBase-root.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
          '.MuiListItemIcon-root': { color: theme.palette.primary.main },
          ':hover': { backgroundColor: theme.palette.primary.lighter }
        }
      }}
    >
      {/* USERNAME */}
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary={user?.id} />
      </ListItemButton>
      {/* TASKS */}
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
      >
        <ListItemIcon>
          {user?.counts?.tasks && user?.counts?.tasks > 0 ? (
            <Badge color="primary" variant="dot">
              <IssuesCloseOutlined />
            </Badge>
          ) : (
            <IssuesCloseOutlined />
          )}
        </ListItemIcon>
        <ListItemText primary={`Tasks (${user?.counts?.tasks})`} />
        <ListItemText primary={<LinkOutlined />} sx={{ textAlign: 'right' }} />
      </ListItemButton>
      {/* NOTIFICATIONS */}
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event) => handleListItemClick(event, 3)}
      >
        <ListItemIcon>
          {user?.counts?.notifications && user?.counts?.notifications > 0 ? (
            <Badge color="primary" variant="dot">
              <BellOutlined />
            </Badge>
          ) : (
            <BellOutlined />
          )}
        </ListItemIcon>
        <ListItemText
          primary={`Notifications (${user?.counts?.notifications})`}
        />
        <ListItemText primary={<LinkOutlined />} sx={{ textAlign: 'right' }} />
      </ListItemButton>
      {/* SETTINGS */}
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) => handleListItemClick(event, 4)}
      >
        <ListItemIcon>
          <SettingOutlined />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        <ListItemText primary={<LinkOutlined />} sx={{ textAlign: 'right' }} />
      </ListItemButton>
    </List>
  );
};

export default HangarTab;
