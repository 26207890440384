import { loginRequest, graphConfig } from '../../../msal';
import { msalInstance } from '../../../index';
import { setLocalStorageValue } from 'utils/storage/localStorage';

/**
 * Call Microsoft Graph API endpoints to fetch user data
 */
export default async function fetchMicrosoftUser() {
  /**
   * If there is no accessToken, call acquireTokenSilent to get a new token
   */
  const account = msalInstance.getActiveAccount();
  if (!account) {
    console.warn(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
    return null;
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  const accessToken = response.accessToken;
  setLocalStorageValue('MicrosoftJWT', accessToken);

  /**
   * Get fetch headers
   */
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  headers.append('ConsistencyLevel', 'eventual');

  const options = {
    method: 'GET',
    headers: headers
  };

  const microsoftGraphApiUrl = graphConfig.graphMeEndpoint;
  if (!microsoftGraphApiUrl) {
    console.error('Environment not set correctly.');
    return null;
  }

  try {
    /**
     * The code below makes three simultaneous calls to Microsoft Graph API endpoints
     */
    const promises = [
      fetch(microsoftGraphApiUrl, options), // Get the current user's profile
      fetch(`${microsoftGraphApiUrl}/photos/48x48/$value`, options), // Get the current user's profile picture
      fetch(
        `${microsoftGraphApiUrl}/memberOf/microsoft.graph.group?$count=true&$filter=startswith(displayName,'LCMS')`,
        options
      ) // Get the current user's relevant application group enrolments (LCMS)
    ];
    const res = await Promise.all(promises);
    const responses = await Promise.all([
      res[0].json(),
      res[1].blob(),
      res[2].json()
    ]);

    return {
      profile: responses[0] ? responses[0] : null,
      profilePicture: responses[1] ? responses[1] : null,
      groups: responses[2].value ? responses[2].value : null
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}
