/**
 * Hangar API header objects
 */

/**
 * Headers used in all API calls to the Hangar via the Aviation Australia API
 */
const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * Get Hangar REST API Headers
 * @param {*} accessToken
 * @return {*} Headers
 */
function getHangarApiHeaders(accessToken: string) {
  return {
    ...commonHeaders,
    'X-Authorization': `${accessToken}`
  };
}

function getHangarApiBlobHeaders(accessToken: string) {
  return {
    'X-Authorization': `${accessToken}`,
    Accept: '*/*',
    'Content-Type': `multipart/form-data`
  };
}

/**
 * Get Hangar REST API IMAGE Headers
 * @param {*} accessToken
 * @return {*} Headers
 */
function getHangarApiImageHeaders(accessToken: string) {
  return {
    'X-Authorization': `${accessToken}`,
    Accept: '*/*'
  };
}

/**
 * Get Hangar Public Headers (No Auth)
 * @param {*} accessToken
 * @return {*} Headers
 */
function getHangarApiHeadersNoAuth() {
  return {
    ...commonHeaders
  };
}

/**
 * Get Hangar Auth Headers with custom headers
 * @param {*} accessToken
 * @param {*} headers
 * @return {*} Headers
 */
function getHangarAuthHeadersCustom(accessToken: string, headers: any) {
  return {
    ...commonHeaders,
    ...headers,
    'X-Authorization': `${accessToken}`
  };
}

export {
  getHangarApiHeaders,
  getHangarApiBlobHeaders,
  getHangarApiImageHeaders,
  getHangarApiHeadersNoAuth,
  getHangarAuthHeadersCustom
};
