import { getLocalStorageValue } from 'utils/storage/localStorage';
import { getHangarTokenRef } from 'utils/storage/references';

/**
 * Get Access Token from storage
 */
export function getHangarAccessToken() {
  const tokenRef = getHangarTokenRef();
  const accessToken = getLocalStorageValue(tokenRef);
  return accessToken;
}
