/**
 * ==============================|| Microsoft Auth Library (MSAL) ||============================== //
 */

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Main Configuration
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT
      ? process.env.REACT_APP_MSAL_CLIENT
      : '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY
      ? process.env.REACT_APP_MSAL_AUTHORITY
      : '', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI
      ? process.env.REACT_APP_MSAL_REDIRECT_URI
      : ''
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_MICROSOFT_GRAPH_ENDPOINT
    ? process.env.REACT_APP_MICROSOFT_GRAPH_ENDPOINT
    : null
};
