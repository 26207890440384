import {
  DashboardOutlined,
  AppstoreOutlined,
  LinkOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  AppstoreOutlined,
  LinkOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: icons.DashboardOutlined,
      url: '/dashboard'
    }
    // {
    //   id: 'attachment-links',
    //   title: 'Attachment Links',
    //   type: 'item',
    //   url: '/tools/attachment-links',
    //   icon: icons.LinkOutlined
    // }
    // {
    //   id: 'group-tools',
    //   title: 'Quick Tools',
    //   icon: icons.AppstoreOutlined,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'attachment-links',
    //       title: 'Attachment Links',
    //       type: 'item',
    //       url: '/tools/attachment-links',
    //       icon: icons.LinkOutlined
    //     }
    //   ]
    // }
  ]
};

export default dashboard;
