/**
 * Get Server Base URL
 */
export function getHangarBaseUrl() {
  return process.env.REACT_APP_HANGAR_BASE_URL;
}

/**
 * Create items URL from item UUID (latest version)
 */
export function createLinkToHangarItemLatestVersion(uuid: string | undefined) {
  return `${getHangarBaseUrl()}/items/${uuid}/0/`;
}

/**
 * Create items URL from item UUID (latest version)
 */
export function createAPILinkToHangarItemLatestVersionAttachment(
  uuid: string,
  attachmentUuid: string
) {
  return `${getHangarBaseUrl()}/integ/gen/${uuid}/0/?attachment.uuid=${attachmentUuid}`;
}

/**
 * Given a JSON object, build a query string
 * TODO - correctly type this
 */
export function buildQueryParams(jsonObject: any) {
  let queryString = '';

  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      const value = jsonObject[key];

      if (value === undefined) continue;

      if (Array.isArray(value)) {
        for (const element of value) {
          if (queryString.length > 0) {
            queryString += '&';
          }
          queryString += `${key}=${encodeURIComponent(element)}`;
        }
      } else {
        if (queryString.length > 0) {
          queryString += '&';
        }
        queryString += `${key}=${encodeURIComponent(value)}`;
      }
    }
  }

  return queryString;
}
