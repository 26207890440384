import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  BookOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  BookOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  title: 'Other',
  type: 'group',
  children: [
    // {
    //   id: 'sample-page',
    //   title: 'Sample Page',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: icons.ChromeOutlined
    // },
    // {
    //   id: 'disabled-menu',
    //   title: 'Disabled Menu',
    //   type: 'item',
    //   url: '#',
    //   icon: icons.StopOutlined,
    //   disabled: true
    // },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://docs.aviationaustralia.aero/books/toolbox-user-guide',
      icon: icons.BookOutlined,
      external: true,
      target: true,
      chip: {
        label: 'Link',
        color: 'secondary',
        size: 'small',
        variant: 'combined'
      }
    },
    {
      id: 'pdd-mos',
      title: 'Manual of Standards',
      type: 'item',
      url: 'https://docs.aviationaustralia.aero/books/pdd-manual-of-standards',
      icon: icons.BookOutlined,
      external: true,
      target: true,
      chip: {
        label: 'Link',
        color: 'secondary',
        size: 'small',
        variant: 'combined'
      }
    }
  ]
};

export default other;
