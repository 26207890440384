/* eslint-disable @typescript-eslint/no-unused-vars */
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import { useTheme } from '@mui/material/styles';

// ==============================|| MAIN LAYOUT ||============================== //

const EditorLayout = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        background: theme.palette.background.paper,
        minHeight: 'calc(100vh)'
      }}
    >
      <Outlet />
    </Box>
  );
};

export default EditorLayout;
