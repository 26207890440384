import { useRef, useState, ReactNode, SyntheticEvent } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';

// project import
import Avatar from 'components/@mantis/material-extended/Avatar';
import MainCard from 'components/app/MainCard';
import Transitions from 'components/@mantis/material-extended/Transitions';
import IconButton from 'components/@mantis/material-extended/IconButton';
import useAuth from 'hooks/useAuth';

// assets
import {
  DatabaseOutlined,
  LogoutOutlined,
  WindowsOutlined
} from '@ant-design/icons';

// types
import { ThemeMode } from 'types/mantis/config';
import useHangarAuth from 'hooks/useHangarAuth';
import {
  getHangarAuthCodeRef,
  getHangarTokenRef
} from 'utils/storage/references';
import { removeLocalStorageValue } from 'utils/storage/localStorage';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import HangarTab from './HangarTab';
import MicrosoftTab from './MicrosoftTab';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

// tab panel wrapper
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  // const navigate = useNavigate();

  const { user } = useAuth();
  const { logout: logout2 } = useHangarAuth();

  const handleLogout = async () => {
    try {
      setOpen(false);
      const tokenRef = getHangarTokenRef();
      const codeRef = getHangarAuthCodeRef();
      removeLocalStorageValue(tokenRef);
      removeLocalStorageValue(codeRef);
      await logout2();
      dispatch(
        openSnackbar({
          open: true,
          message: 'Logged out of the Hangar',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {
            bgcolor:
              theme.palette.mode === 'dark'
                ? 'secondary.light'
                : 'secondary.lighter'
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {user?.profilePicture ? (
            <Avatar
              alt="profile user"
              src={URL.createObjectURL(user?.profilePicture)}
              size="sm"
            />
          ) : null}
          <Stack>
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {`${user?.givenName} ${user?.surname}`}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{
          zIndex: 1204
        }}
        transition
        // disablePortal // Commented out so that I can apply zIndex to this component
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            <Avatar
                              alt="profile user"
                              src={URL.createObjectURL(user.profilePicture)}
                              sx={{ width: 32, height: 32 }}
                            />
                            <Stack>
                              <Typography variant="h6">{user?.name}</Typography>

                              <Typography variant="body2" color="textSecondary">
                                Microsoft Account
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Hangar Logout">
                            <IconButton
                              size="large"
                              sx={{ color: 'text.primary' }}
                              onClick={handleLogout}
                            >
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {/* <Box sx={{ textAlign: 'center' }}>
                        {user?.roles.map((e: any, i: number) => {
                          return (
                            <Typography
                              display="block"
                              variant="caption"
                              color="secondary"
                            >{`${e.displayName}`}</Typography>
                          );
                        })}
                      </Box> */}
                    </CardContent>
                    {/* Shows the required contexts to run the app */}
                    {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs
                            variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                            aria-label="profile tabs"
                            TabIndicatorProps={{
                              sx: {
                                backgroundColor: theme.palette.primary.main
                              }
                            }}
                            sx={{
                              '& button:hover': {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.lighter
                              },
                              '& button.Mui-selected': {
                                color: theme.palette.primary.main
                              }
                            }}
                          >
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={
                                <DatabaseOutlined
                                  style={{
                                    marginBottom: 0,
                                    marginRight: '10px'
                                  }}
                                />
                              }
                              label="Hangar"
                              {...a11yProps(1)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={
                                <WindowsOutlined
                                  style={{
                                    marginBottom: 0,
                                    marginRight: '10px'
                                  }}
                                />
                              }
                              label="Microsoft"
                              {...a11yProps(0)}
                            />
                          </Tabs>
                        </Box>
                        {/* The Hangar Tab */}
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <HangarTab handleLogout={handleLogout} />
                        </TabPanel>
                        {/* Microsoft Tab */}
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <MicrosoftTab />
                        </TabPanel>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
