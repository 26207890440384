import { createContext, useReducer, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// mantis
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/hangarAuth';

// integration
import {
  getCurrentAuthSession,
  getHangarAccessTokenFromAuthCode,
  refreshHangarSSOToken
} from 'app/integration/server/library/auth';
import { getHangarAccessToken } from 'app/integration/server/utils/tokens';

// utils
import {
  removeLocalStorageValue,
  setLocalStorageValue
} from 'utils/storage/localStorage';
import {
  getHangarAuthCodeRef,
  getHangarTokenRef
} from 'utils/storage/references';

// components
import Spinner from 'components/app/AppSpinner';
import useAuth from 'hooks/useAuth';
import { validate } from 'uuid';
import { HangarSSOToken } from 'app/types/contexts/hangar.types';

// initial state of the login context
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| MICROSOFT CONTEXT & PROVIDER ||============================== //

export const HangarContext = createContext<any>(null);

// TODO - type this properly
const HangarProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  // Microsoft Auth
  const { isLoggedIn, user } = useAuth();

  // Location
  const location = useLocation();
  const params = location.search;
  const authCode = new URLSearchParams(params).get('code');
  const returnToUrl = new URLSearchParams(params).get('state');

  // Check image
  const checkImageUrl = process.env.REACT_APP_HANGAR_AUTH_CHECK_IMAGE;

  // State
  const [fetching, setFetching] = useState(true);
  const [hangarSsoError, setHangarSsoError] = useState(false);
  const [authTokenConsumed, setAuthTokenConsumed] = useState(false);
  const [hangarSsoRetry, setHangarSsoRetry] = useState(0);
  const [hangarSSOToken, setHangarSSOToken] = useState<HangarSSOToken | null>(
    null
  );

  /**
   * Fetch a Hangar access token after authCode verification
   */
  const fetchHangarAccessToken = async (authCode: string): Promise<void> => {
    setFetching(true);
    const tokenRef = getHangarTokenRef();

    // get access token from Hangar
    const res = await getHangarAccessTokenFromAuthCode(authCode);
    const newAccessToken = res?.data;
    if (!newAccessToken)
      console.error('Failed to retreive access token from server.');
    setAuthTokenConsumed(true);

    // If call to get access token fails
    if (res.status !== 200) {
      console.error('Failed to retreive access token from server.');
      setFetching(false);
      dispatch({
        type: LOGOUT
      });
      removeLocalStorageValue(tokenRef);
      return;
    }

    // get user session from Hangar
    const res2 = await getCurrentAuthSession(newAccessToken, user.id);
    const session = res2.data;
    setHangarSSOToken(session?.token);

    // If call to get access token fails
    if (res2.status !== 200) {
      console.error('Failed to retreive user session from server.');
      setFetching(false);
      dispatch({
        type: LOGOUT
      });
      removeLocalStorageValue(tokenRef);
      navigate('login');
    } else {
      // success & login
      setLocalStorageValue(tokenRef, newAccessToken);
      setFetching(false);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            id: session.id,
            username: session.username,
            email: session.emailAddress,
            name: `${session.firstName} ${session.lastName}`,
            firstName: session.firstName,
            lastName: session.lastName,
            counts: session.counts,
            accessToken: newAccessToken,
            ssoToken: session.token
          }
        }
      });
      navigate('dashboard');
    }
  };

  /**
   * Check localStorage for an OAuth service token
   */
  const getHangarAuthSession = async (accessToken: string) => {
    try {
      // Get current user session in the Hangar
      setFetching(true);
      const res = await getCurrentAuthSession(accessToken, user?.id);
      const session = res.data;
      setHangarSSOToken(session?.token);
      setFetching(false);

      /**
       * If fails to get current session (not 200)
       * Present Error 500 to the user. The app cannot continue if the server is down.
       */
      if (res.status !== 200) {
        console.error('Failed to retreive user session from server.');
        dispatch({
          type: LOGOUT
        });
        return;
      } else {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: {
              id: session.id,
              username: session.username,
              email: session.emailAddress,
              name: `${session.firstName} ${session.lastName}`,
              firstName: session.firstName,
              lastName: session.lastName,
              counts: session.counts,
              accessToken: accessToken,
              ssoToken: session.token
            }
          }
        });
        return;
      }
    } catch (err) {
      dispatch({
        type: LOGOUT
      });
      return;
    }
  };

  /**
   * Verify an authcode
   */
  const verifyAuthCode = async (authCode: string) => {
    const codeRef = getHangarAuthCodeRef();
    setLocalStorageValue(codeRef, authCode);
    fetchHangarAccessToken(authCode);
  };

  /**
   * Refresh a Hangar access token
   */
  useEffect(() => {
    /**
     * On-load run a check to see if the user is logged in
     */
    const accessToken = getHangarAccessToken();
    const accessTokenValid = accessToken && validate(accessToken);

    const runHangarContext = async () => {
      /**
       * if auth code exists and Hangar access token does not
       */
      if (!authTokenConsumed && authCode && validate(authCode)) {
        if (returnToUrl)
          setLocalStorageValue(
            'pathBeforeRedirect',
            encodeURIComponent(returnToUrl) !== encodeURIComponent('/login')
              ? returnToUrl
              : '/dashboard'
          ); // Set the path to return to after authentication
        verifyAuthCode(authCode);
        return;
      }

      /**
       * If Microsoft is not logged in or Hangar access token not found, redirect to login page
       */
      if (!isLoggedIn || !accessTokenValid) {
        setFetching(false);
        if (location.pathname !== '/login') navigate('login');
        return;
      }

      /**
       * If not already logged in to the Hangar (no active session) but token exists
       */
      if (!state.isLoggedIn && accessTokenValid) {
        getHangarAuthSession(accessToken);
        return;
      }
    };

    runHangarContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, isLoggedIn, state.isLoggedIn, navigate]);

  /**
   * Logout of the app
   */
  const logout = async () => {
    dispatch({
      type: LOGOUT
    });
  };

  /**
   * Single-Sign On into the Hangar
   * This is using a browser "hack" to obtain a cookie silently from the server.
   * In the render of this component, an image will fetch from the Hangar:
   * 1. If it fetches fine, the user is already in.
   * 2. If it fails it will try a token returned by the session.
   * 3. If that fails it will try to retreive a new token.
   * 4. If that fails it will ask the user to login manually.
   */
  const hangarSilentSSOLogin = async (ssoToken: string | null) => {
    if (!ssoToken) {
      // If no token provided, fetch a new one.
      const accessToken = getHangarAccessToken();
      if (accessToken) {
        const res = await refreshHangarSSOToken(user.id, accessToken);
        const ssoToken = res?.data?.accessToken;
        const checkImageUrlWithToken = checkImageUrl?.includes(
          '?attachment.uuid'
        )
          ? `${checkImageUrl}&token=${ssoToken}`
          : `${checkImageUrl}?token=${ssoToken}`;
        const img = new Image();
        img.onerror = () => {
          setHangarSsoError(true);
        };
        img.onload = () => {};
        img.src = checkImageUrlWithToken;
        setHangarSsoRetry(hangarSsoRetry + 1);
      }
    } else {
      // Token provided, use it.
      const checkImageUrlWithToken = checkImageUrl?.includes('?attachment.uuid')
        ? `${checkImageUrl}&token=${hangarSSOToken?.accessToken}`
        : `${checkImageUrl}?token=${hangarSSOToken?.accessToken}`;
      const img = new Image();
      img.onerror = () => {
        hangarSilentSSOLogin(null);
      };
      img.onload = () => {};
      img.src = checkImageUrlWithToken;
      setHangarSsoRetry(hangarSsoRetry + 1);
    }
  };

  return (
    <HangarContext.Provider
      value={{
        ...state,
        logout
      }}
    >
      {hangarSSOToken?.accessToken && (
        <Box sx={{ display: 'none' }}>
          <img
            src={checkImageUrl}
            alt="check"
            onLoad={() => {}}
            onError={async ({ currentTarget }) => {
              console.warn(
                `Failed to authenticate with the hangar using SSO resource ${checkImageUrl}, please ensure this is a valid resource.`
              );
              if (hangarSsoRetry === 0) {
                hangarSilentSSOLogin(hangarSSOToken?.accessToken);
              } else if (hangarSsoRetry < 2) {
                hangarSilentSSOLogin(null);
              }
            }}
          ></img>
        </Box>
      )}
      <Dialog
        open={hangarSsoError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle id="alert-dialog-title">
            Single sign-on to the Hangar failed. Please reload the page.
          </DialogTitle>
          <DialogActions>
            {/* <Button color="error" onClick={() => setHangarSsoError(false)}>Close</Button> */}
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Restart application
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {fetching ? (
        <Spinner message={'Authenticating with the Hangar...'} />
      ) : null}
      {children}
    </HangarContext.Provider>
  );
};

export default HangarProvider;
