// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Theme } from 'types/mantis/theme';

const SpinnerWrapper = styled('div')(() => ({}));

const styles = (theme: Theme) => {
  return {
    '& .overlay': {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: '#FFF',
      zIndex: 9999,
      marginTop: '0px'
    },
    '& .overlay__inner': {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    '& .overlay__content': {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    },
    '& .spinner': {
      width: '75px',
      height: '75px',
      display: 'inline-block',
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
      borderTopColor: '#fff',
      animation: 'spin 1s infinite linear',
      borderRadius: '100%',
      borderStyle: 'solid'
    },
    '@keyframes spin': {
      '100%': {
        opacity: 0,
        transform: 'rotate(360deg)'
      }
    }
  };
};

// ==============================|| Loader ||============================== //

const Spinner = ({ message }: { message: string }) => {
  const theme = useTheme();
  return (
    <SpinnerWrapper sx={styles(theme)}>
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner"></span>
            <p>
              <strong>{message ? message : 'Loading...'}</strong>
            </p>
          </div>
        </div>
      </div>
    </SpinnerWrapper>
  );
};

export default Spinner;
