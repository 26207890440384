import { useRoutes, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useHangarAuth from 'hooks/useHangarAuth';
import { isAllowed } from 'roles';
import LoginRoutes from './login/LoginRoutes';
import DeveloperRoutes from './authenticated/1_DeveloperRoutes';
import ManagerRoutes from './authenticated/2_ManagerRoutes';
import DesignerRoutes from './authenticated/3_DesignerRoutes';
import SMERoutes from './authenticated/4_SMERoutes';
import InstructorRoutes from './authenticated/5_InstructorRoutes';
import { useEffect } from 'react';
import { setLocalStorageValue } from 'utils/storage/localStorage';
import { RoleMapping } from 'roles/lists/groupsToAppRoles';

// ==============================|| ROUTING RENDER ||============================== //

/**
 * Create route permissions based on the users role
 * Note: This does not change the navigation menu, only the routes the user can access.
 * See menu-items to update the navigation menu.
 */
export default function ThemeRoutes() {
  const location = useLocation();

  /**
   * Ensure the user is authenticated before rendering the routes
   */
  const { user: microsoftUser } = useAuth();
  const { user: hangarUser } = useHangarAuth();
  const roles = microsoftUser?.roles;
  const msUser = microsoftUser?.userPrincipalName?.toLowerCase();
  const haUser = hangarUser?.username?.toLowerCase();
  const isUserAuth = msUser === haUser;

  /**
   * Confirm the two authentication methods are syncronised and valid.
   */
  useEffect(() => {
    if (!isUserAuth) {
      /**
       * Set the path before redirecting to login
       * This is used to redirect the user back to the page they were on before being redirected to login
       */
      setLocalStorageValue(
        'pathBeforeRedirect',
        encodeURIComponent(location.pathname) !== encodeURIComponent('/login')
          ? location.pathname
          : '/dashboard'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuth]);

  /**
   * Determine the routes to use based on the users role
   */
  const checkRoutes = (userRoles: RoleMapping[]) => {
    if (isUserAuth && isAllowed(userRoles, ['developer'])) {
      /**
       * Developer Routes
       */
      return [DeveloperRoutes];
    } else if (isUserAuth && isAllowed(userRoles, ['admin'])) {
      /**
       * Manager Routes (PDD Manager)
       */
      return [ManagerRoutes];
    } else if (isUserAuth && isAllowed(userRoles, ['designer', 'tm', 'ce'])) {
      /**
       * Designer Routes (PDD Staff, Chief Examiner, Training Manager)
       */
      return [DesignerRoutes];
    } else if (isUserAuth && isAllowed(userRoles, ['sme'])) {
      /**
       * SME Routes (Elevated Instructors)
       */
      return [SMERoutes];
    } else if (isUserAuth && isAllowed(userRoles, ['instructor'])) {
      /**
       * Instructor Routes (Instructors)
       */
      return [InstructorRoutes];
    } else if (isUserAuth && isAllowed(userRoles, ['staff'])) {
      /**
       * Staff Routes (All Staff)
       * At this stage, instructors and all staff see the same routes in the Toolbox
       */
      return [InstructorRoutes];
    } else {
      return [LoginRoutes];
    }
  };

  const availableRoutes = checkRoutes(roles);
  return useRoutes(availableRoutes);
}
