/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  useReducer,
  useState,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react';

import { MsalProvider, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

// reducers
import { LOGIN } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { loginRequest } from '../msal';
import { mapEntraGroupsToAppRoles } from '../roles';
import fetchMicrosoftUser from 'app/integration/msal/fetchMicrosoftUser';
import { MicrosoftUser } from 'app/types/contexts/microsoft.types';

import Spinner from 'components/app/AppSpinner';
import { groupsToAppRoles } from 'roles/lists/groupsToAppRoles';
import { isDeveloper } from 'roles/departments/pdd';

// ==============================|| MICROSOFT CONTEXT & PROVIDER ||============================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

export const MicrosoftContext = createContext<any>(null); // TODO - Typecast this correctly

interface MicrosoftActiveAccountProps {
  setFetching: Dispatch<SetStateAction<boolean>>;
  dispatch: Dispatch<any>;
  children: JSX.Element;
}

/**
 * Microsoft Context Provider
 */
const MicrosoftActiveAccount = ({
  setFetching,
  dispatch,
  children
}: MicrosoftActiveAccountProps) => {
  const { instance, inProgress }: any = useMsal(); // TODO - Typecast this correctly

  const fetchUser = async () => {
    setFetching(true);

    try {
      /**
       * Get Account Details via Microsoft Graph
       */
      const response = await fetchMicrosoftUser();
      if (!response) {
        setFetching(false);
        return;
      }

      const profileData = response?.profile; // profile data response
      const profilePicture = response?.profilePicture; // profile picture response
      const groups = response?.groups;

      let userObject: MicrosoftUser = {
        id: profileData.id,
        email: profileData.mail,
        name: profileData.displayName,
        jobTitle: profileData.jobTitle,
        mobilePhone: profileData.mobilePhone,
        givenName: profileData.givenName,
        surname: profileData.surname,
        officeLocation: profileData.officeLocation,
        businessPhones: profileData.businessPhones,
        userPrincipalName: profileData.userPrincipalName,
        profilePicture: profilePicture,
        groups: groups?.map((e: any) => e.displayName),
        roles: mapEntraGroupsToAppRoles(groups.map((e: any) => e.id)),
        /**
         * @TODO REMOVE
         * FOR TESTING PURPOSES ONLY
         */
        // roles: [groupsToAppRoles[2]] // Training Manager
        // roles: [groupsToAppRoles[3]] // Chief Examiner
        // roles: [groupsToAppRoles[4]] // Courseware Designer
        // roles: [groupsToAppRoles[5], groupsToAppRoles[6]] // Subject Matter Expert
        // roles: [groupsToAppRoles[6]], // Instructor
        isImpersonating: false
      };
      const localOverride = localStorage.getItem('toolbox-impersonate-role');
      const localRole = groupsToAppRoles.find((r) => r.name === localOverride);
      if (localRole !== undefined && isDeveloper(userObject)) {
        console.warn(
          `DEVELOPER ONLY: A local role "${localRole.name}" is overriding your actual role. This override is CLIENT only (i.e. your SERVER TOKEN is not affected).`
        );
        userObject.roles = [localRole];
        userObject.isImpersonating = true;
      }
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: userObject
        }
      });
      setFetching(false);
      return;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          ...loginRequest,
          account: instance.getActiveAccount()
        });
      }
      console.error(e);
      setFetching(false);
      return;
    }
  };

  /**
   * Get Account Details via Microsoft Graph
   */
  useEffect(() => {
    /**
     * Wait until MSAL is initialized
     */
    if (inProgress === 'none' && instance.initialized) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, instance.initialized]);

  return <>{children}</>;
};

interface MicrosoftProviderProps {
  pca: any;
  children: JSX.Element;
}

/**
 * Microsoft Provider
 */
const MicrosoftProvider = ({ pca, children }: MicrosoftProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [fetching, setFetching] = useState(true);

  return (
    <MsalProvider instance={pca}>
      <MicrosoftActiveAccount setFetching={setFetching} dispatch={dispatch}>
        <MicrosoftContext.Provider
          value={{
            ...state
          }}
        >
          {fetching ? (
            <Spinner message={'Authenticating with Microsoft...'} />
          ) : (
            children
          )}
        </MicrosoftContext.Provider>
      </MicrosoftActiveAccount>
    </MsalProvider>
  );
};

export default MicrosoftProvider;
