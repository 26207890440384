// action - state management
import { HangarAuthActionProps, HangarAuthProps } from 'types/mantis/auth';
import { LOGIN, LOGOUT } from './actions';

// initial state
export const initialState: HangarAuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| HANGAR AUTH REDUCER ||============================== //

const auth = (state = initialState, action: HangarAuthActionProps) => {
  switch (action.type) {
    case LOGIN: {
      const user = action?.payload?.user;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
