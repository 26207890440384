import axios, { AxiosError } from 'axios';
// import rateLimit from 'axios-rate-limit';
import { handleErrorResponse } from './errorHandler';

/**
 * Axios JSON Request
 * This is the default request type
 */
export async function axiosRequestJSON(
  method: string,
  url: string,
  headers: object,
  body: any,
  timeout?: number
) {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data: body,
      timeout
    });
    return response;
  } catch (error) {
    return handleErrorResponse(error as AxiosError);
  }
}

export async function axiosRequest(
  method: string,
  url: string,
  headers: object,
  body: any,
  timeout?: number
) {
  try {
    const response = await axios({
      method,
      url,
      headers,
      // responseType: 'blob',
      data: body,
      timeout
    });
    return response;
  } catch (error) {
    return handleErrorResponse(error as AxiosError);
  }
}
