/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
// import Footer from './Footer';

import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@mantis/material-extended/Breadcrumbs';

import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';

// types
import { MenuOrientation } from 'types/mantis/config';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  let currentLocation = location.pathname;
  let showTitle = true;

  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation, showLayout } = useConfig();
  const { user } = useAuth();

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const [noPadding, setNoPadding] = useState(false);
  const [noBreadcrumb, setNoBreadcrumb] = useState(false);

  /**
   * Determine the background of the app based on the current route
   */
  // const determineAppBackground = () => {
  //   const whiteBackgroundRoutes = ['/editor/'];
  //   const origin = window.location.href;
  //   if (whiteBackgroundRoutes.some((route) => origin.includes(route))) {
  //     return theme.palette.background.paper;
  //   } else return theme.palette.background.default;
  // };

  // const bgColor = determineAppBackground();

  /**
   * @CUSTOM
   * If the user is on the drawers page -> Custom layout options
   */
  useEffect(() => {
    if (currentLocation.includes('/drawers')) {
      setNoPadding(true);
      setNoBreadcrumb(true);
    } else if (currentLocation.includes('/editor/')) {
      setNoPadding(true);
      setNoBreadcrumb(true);
    } else {
      setNoPadding(false);
      setNoBreadcrumb(false);
    }
  }, [currentLocation]);

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!matchDownXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownXL]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {showLayout && (
        <>
          <Header />
          {!isHorizontal ? <Drawer /> : <HorizontalBar />}
        </>
      )}

      <Box
        component="main"
        sx={
          noPadding
            ? {
                width: 'calc(100% - 260px)',
                flexGrow: 1,
                p: { xs: 2, sm: 3 },
                background: theme.palette.background.paper
              }
            : {
                width: 'calc(100% - 260px)',
                flexGrow: 1,
                p: { xs: 2, sm: 3 },
                background: theme.palette.background.default
              }
        }
      >
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {!noBreadcrumb && (
            <Breadcrumbs
              navigation={navigation(user)}
              title={showTitle}
              titleBottom
              card={false}
              divider={false}
            />
          )}
          <Outlet />
          {/* <Footer /> */}
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
