/**
 * Axios Response Library
 */

interface AxiosResponse {
  status: number;
  message: string;
  data: object | null;
}

/**
 * Axios Response
 */
export function generateAxiosResponse(
  status: number,
  message: string,
  data: object | null
): AxiosResponse {
  return {
    status,
    message,
    data
  };
}
