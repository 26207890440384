import Login from '../../pages/auth/login';

const AuthLogin = Login;
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: '*',
          element: <AuthLogin />
        }
      ]
    }
  ]
};

export default LoginRoutes;
