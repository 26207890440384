/* eslint-disable @typescript-eslint/no-unused-vars */

import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project import
import MainCard from 'components/app/MainCard';

// assets
import {
  ApartmentOutlined,
  HomeOutlined,
  HomeFilled,
  FolderOpenOutlined
} from '@ant-design/icons';

// types
import { OverrideIcon } from 'types/mantis/root';
import { NavItemType } from 'types/mantis/menu';
import { noBreadcrumb, noTitle } from 'config';
import { createLinkToHangarItemLatestVersion } from 'app/integration/server/utils/urls';
import { validate } from 'uuid';
import { removeTrailingSlashFromUrl } from 'utils/strings';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const { id } = useParams();
  const [main, setMain] = useState<NavItemType | undefined>();
  const [item, setItem] = useState<NavItemType>();

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  useEffect(() => {
    navigation?.items?.map((menu: NavItemType, index: number) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu as { children: NavItemType[]; type?: string });
      }
      return false;
    });
  });

  /**
   * Custom logic added to ensure that a trailing slash doesn't break the menu selection functionality.
   * @customLocationWithoutTrailingSlash - The location without a trailing slash so /workflows/ is treated like /workflows
   */
  let customLocation = location.pathname;
  let customLocationWithoutTrailingSlash =
    removeTrailingSlashFromUrl(customLocation);

  // set active item state
  const getCollapse = (menu: NavItemType) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
          if (collapse.url === customLocationWithoutTrailingSlash) {
            setMain(collapse);
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === 'item') {
          if (customLocationWithoutTrailingSlash === collapse.url) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? (
    <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} />
  ) : (
    '/'
  );

  let mainContent;
  let itemContent;
  let breadcrumbContent: ReactElement = <Typography />;
  let itemTitle: NavItemType['title'] = '';
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main && main.type === 'collapse' && main.breadcrumbs === true) {
    CollapseIcon = main.icon ? main.icon : ApartmentOutlined;
    mainContent = (
      <Typography
        component={Link}
        to={document.location.pathname}
        variant="h6"
        sx={{ textDecoration: 'none' }}
        color="textSecondary"
      >
        {icons && <CollapseIcon style={iconSX} />}
        {main.title}
      </Typography>
    );
    breadcrumbContent = (
      <MainCard
        border={card}
        sx={
          card === false
            ? { mb: 3, bgcolor: 'transparent', ...sx }
            : { mb: 3, ...sx }
        }
        {...others}
        content={card}
        shadow="none"
      >
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          <Grid item>
            <MuiBreadcrumbs
              aria-label="breadcrumb"
              maxItems={maxItems || 8}
              separator={separatorIcon}
            >
              <Typography
                component={Link}
                to="/dashboard"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: 'none' }}
              >
                {icons && <HomeOutlined style={iconSX} />}
                {icon && !icons && (
                  <HomeFilled style={{ ...iconSX, marginRight: 0 }} />
                )}
                {(!icon || icons) && 'Home'}
              </Typography>
              {mainContent}
            </MuiBreadcrumbs>
          </Grid>
          {title && titleBottom && (
            <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
              <Typography variant="h2">{main.title}</Typography>
            </Grid>
          )}
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  // items
  if (item && item.type === 'item') {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : ApartmentOutlined;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {icons && <ItemIcon style={iconSX} />}
        {itemTitle}
      </Typography>
    );

    // main
    if (
      item.breadcrumbs !== false &&
      !noBreadcrumb.some((route: string) =>
        customLocationWithoutTrailingSlash.includes(route)
      )
    ) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={
            card === false
              ? { mb: 3, bgcolor: 'transparent', ...sx }
              : { mb: 3, ...sx }
          }
          {...others}
          content={card}
          shadow="none"
        >
          <Grid
            container
            direction={rightAlign ? 'row' : 'column'}
            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
            alignItems={rightAlign ? 'center' : 'flex-start'}
            spacing={1}
          >
            {title &&
              !titleBottom &&
              !noTitle.some((route: string) =>
                customLocationWithoutTrailingSlash.includes(route)
              ) && (
                <Grid item>
                  <Typography variant="h2">{item.title}</Typography>
                </Grid>
              )}
            <Grid item>
              <MuiBreadcrumbs
                aria-label="breadcrumb"
                maxItems={maxItems || 8}
                separator={separatorIcon}
              >
                <Typography
                  component={Link}
                  to="/dashboard"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none' }}
                >
                  {icons && <HomeOutlined style={iconSX} />}
                  {icon && !icons && (
                    <HomeFilled style={{ ...iconSX, marginRight: 0 }} />
                  )}
                  {(!icon || icons) && 'Home'}
                </Typography>
                {mainContent}
                {itemContent}
                {id && validate(id) && (
                  <Typography
                    variant="h6"
                    sx={{ textDecoration: 'none' }}
                    color="textSecondary"
                  >
                    {`${id}`}{' '}
                    <a
                      style={{ marginLeft: '5px' }}
                      target="_blank"
                      rel="noreferrer"
                      href={`${createLinkToHangarItemLatestVersion(id)}`}
                    >
                      <FolderOpenOutlined />
                    </a>
                  </Typography>
                )}
              </MuiBreadcrumbs>
            </Grid>
            {title &&
              titleBottom &&
              !noTitle.some((route: string) =>
                customLocationWithoutTrailingSlash.includes(route)
              ) && (
                <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
                  <Typography variant="h2">{item.title}</Typography>
                </Grid>
              )}
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
