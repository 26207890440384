import {
  // ChromeOutlined,
  ApiOutlined,
  LayoutOutlined,
  EditOutlined,
  FileDoneOutlined,
  BlockOutlined,
  ToolOutlined,
  DiffOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

// icons
const icons = {
  // ChromeOutlined,
  ApiOutlined,
  LayoutOutlined,
  EditOutlined,
  FileDoneOutlined,
  BlockOutlined,
  ToolOutlined,
  DiffOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - PERMISSIONS ||============================== //

const developer = {
  id: 'group-developer',
  title: 'Developer',
  type: 'group',
  children: [
    // {
    //   id: 'icons',
    //   title: 'Empty State Icons',
    //   type: 'item',
    //   url: '/icons',
    //   icon: icons.FileDoneOutlined
    // },
    {
      id: 'course-migration',
      title: 'Course Migration',
      type: 'item',
      chip: {
        label: 'admin',
        color: 'error',
        size: 'small',
        variant: 'combined'
      },
      url: '/migration',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'data-mapping-tool',
      title: 'Mapping Tool',
      type: 'item',
      url: '/data/mapping',
      icon: icons.DiffOutlined
    },
    {
      id: 'data-units',
      title: 'Units of Competency',
      type: 'item',
      url: '/data/units',
      icon: icons.DiffOutlined
    },
    {
      id: 'testing',
      title: 'Developer Testing',
      type: 'item',
      url: '/testing',
      icon: icons.ToolOutlined
    }
    // {
    //   id: 'html-slides',
    //   title: 'Test Slide Deck',
    //   type: 'item',
    //   url: '/html/slides',
    //   icon: icons.ToolOutlined
    // }
    // {
    //   id: 'apidocs',
    //   title: 'API Documentation',
    //   type: 'item',
    //   url: '/apidocs',
    //   icon: icons.ApiOutlined
    // },
    // {
    //   id: 'drawers',
    //   title: 'Drawers',
    //   type: 'item',
    //   url: '/drawers',
    //   icon: icons.BlockOutlined
    // }
    // {
    //   id: 'slides',
    //   title: 'Slides',
    //   type: 'item',
    //   icon: icons.LayoutOutlined,
    //   url: '/slides'
    // }
    // {
    //   id: 'slidebuilder',
    //   title: <FormattedMessage id="slide-builder" />,
    //   type: 'item',
    //   icon: icons.LayoutOutlined,
    //   url: '/slidebuilder',
    // },
  ]
};

export default developer;
