import {
  RoleEnum,
  RoleMapping,
  groupsToAppRoles
} from './lists/groupsToAppRoles';

/**
 * Map Microsoft Entra Groups to Application Role
 */
export function mapEntraGroupsToAppRoles(groupsIds: string[]): RoleMapping[] {
  return groupsToAppRoles.filter((role: RoleMapping) =>
    groupsIds.includes(role.id)
  );
}

/**
 * Determine if a user is allowed to access an app feature
 */
export function isAllowed(
  roles: RoleMapping[],
  allowedRoleEnums: RoleEnum[]
): boolean {
  if (!roles) return false;
  return roles.some((role: RoleMapping) =>
    allowedRoleEnums.includes(role.role)
  );
}
