/**
 * Get Hangar Environment Specific Token Reference
 */
export function getHangarTokenRef() {
  if (process.env.REACT_APP_HANGAR_BASE_URL?.includes('dev'))
    return 'devHangarJWT';
  else if (process.env.REACT_APP_HANGAR_BASE_URL?.includes('preprod'))
    return 'preProdHangarJWT';
  else return 'prodHangarJWT';
}

/**
 * Get Hangar Authorization Code Environment Reference
 */
export function getHangarAuthCodeRef() {
  if (process.env.REACT_APP_HANGAR_BASE_URL?.includes('dev')) return 'devAuth';
  else if (process.env.REACT_APP_HANGAR_BASE_URL?.includes('preprod'))
    return 'preProdAuth';
  else return 'prodAuth';
}
