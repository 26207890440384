import { AxiosError } from 'axios';
import { generateAxiosResponse } from './axiosResponses';

/**
 * Handle an Axios Error Response
 */
export function handleErrorResponse(axiosError: AxiosError) {
  let { code, message, response } = axiosError;
  let status = response?.status ? response.status : 500;

  // Log error
  console.error(`Axios API error: ${code}`);
  console.error(`Status: ${status}`);
  console.error(`Message: ${message}`);

  // Generalise error responses
  switch (code) {
    case 'ERR_NETWORK':
      status = 503;
      message = 'Could not connect to the server';
      return generateAxiosResponse(
        status,
        message ? message : 'An error occurred',
        response?.data ? response.data : null
      );
    default:
      return generateAxiosResponse(
        status,
        message ? message : 'An error occurred',
        response?.data ? response.data : null
      );
  }
}
