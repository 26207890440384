import { getEnvironmentVariable } from 'app/service/utils/environment';

export type RoleEnum =
  | 'developer'
  | 'admin'
  | 'tm'
  | 'ce'
  | 'designer'
  | 'sme'
  | 'instructor'
  | 'staff';

export interface RoleMapping {
  id: string;
  displayName: string;
  role: RoleEnum;
  name: string;
}

/**
 * Role mappings between AD groups and app roles
 */
export const groupsToAppRoles: RoleMapping[] = [
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_DEVELOPER`),
    displayName: 'LCMS_Developers',
    role: 'developer',
    name: 'Developer'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_PDDMANAGER`),
    displayName: 'LCMS_PDDManager',
    role: 'admin',
    name: 'PDD Manager'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_TM`),
    displayName: 'LCMS_TM',
    role: 'tm',
    name: 'Training Manager'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_CE`),
    displayName: 'LCMS_CE',
    role: 'ce',
    name: 'Chief Examiner'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_PDD`),
    displayName: 'LCMS_PDD',
    role: 'designer',
    name: 'PDD Staff'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_SME`),
    displayName: 'LCMS_SME',
    role: 'sme',
    name: 'Subject Matter Expert'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_INSTRUCTOR`),
    displayName: 'LCMS_Instructor',
    role: 'instructor',
    name: 'Instructor'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_MARKETING`),
    displayName: 'LCMS_Marketing',
    role: 'staff',
    name: 'Marketing'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_QA`),
    displayName: 'LCMS_QA',
    role: 'staff',
    name: 'Quality Assurance'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_STUDENTADMIN`),
    displayName: 'LCMS_StudentAdmin',
    role: 'staff',
    name: 'Training Admin'
  },
  {
    id: getEnvironmentVariable(`REACT_APP_AD_GROUP_ASSESSMENT`),
    displayName: 'LCMS_Assessment',
    role: 'staff',
    name: 'Assessment'
  }
];
