import Button from '@mui/material/Button';
import { UnlockOutlined } from '@ant-design/icons';
import { Box, Typography } from '@mui/material';
import { getHangarAuthorisationCodeGrant } from 'app/integration/server/oauth/oauth';

/**
 * Hangar sign in button
 */
export const HangarSignInButton = ({ path }: any) => {
  /**
   * Navigate to the Hangar OAuth flow
   */
  const launchHangarOAuthFlow = (path: any) => {
    const url = getHangarAuthorisationCodeGrant(path);
    window.location.replace(url);
  };

  return (
    <Box>
      <Typography variant="body1">
        Please use your staff <strong>email</strong> and{' '}
        <strong>password</strong> to sign in to the following screen if
        prompted.
      </Typography>
      <Button
        variant="contained"
        startIcon={<UnlockOutlined />}
        onClick={() => launchHangarOAuthFlow(path)}
        color="primary"
        size="large"
        sx={{
          padding: '10px 30px',
          fontSize: '14pt',
          fontWeight: 700,
          mt: '15px'
        }}
      >
        Authenticate Device
      </Button>
      <Typography variant="body1" sx={{ mt: '15px', fontWeight: 900 }}>
        You should only need to do this once per new device until the login
        expires.
      </Typography>
    </Box>
  );
};
