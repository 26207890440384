import { Alert, Grid, Stack, Typography } from '@mui/material';
import AuthWrapper from 'sections/auth/AuthWrapper';
import { MicrosoftSignInButton } from './components/MicrosoftSignIn';
import { HangarSignInButton } from './components/HangarSignIn';
import useAuth from 'hooks/useAuth';
import { getLocalStorageValue } from 'utils/storage/localStorage';

import appLogo from 'assets/images/toolbox/logo2.svg';
import companyLogo from 'assets/images/logo.png';
import { getEnvironmentVariable } from 'app/service/utils/environment';

// ================================|| LOGIN ||================================ //

const Login = () => {
  const { isLoggedIn: authenticatedWithMicrosoft, user: microsoftUser } =
    useAuth();
  const microsoftEmail = microsoftUser?.email?.toLowerCase();
  const pathBeforeRedirect = getLocalStorageValue('pathBeforeRedirect');

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack sx={{ textAlign: 'center' }}>
            <img
              src={appLogo}
              alt="Toolbox application logo"
              style={{ width: '100%', margin: 'auto' }}
            />
            <br />
            <img
              src={companyLogo}
              alt="Aviation Australia logo"
              style={{
                width: '70%',
                margin: 'auto',
                paddingLeft: '50px',
                paddingRight: '50px'
              }}
            />
            <br />
            {!authenticatedWithMicrosoft && <MicrosoftSignInButton />}
            {authenticatedWithMicrosoft && (
              <>
                <Alert color="success" sx={{ mb: '10px' }}>
                  Active Microsoft account: <strong>{microsoftEmail}</strong>
                </Alert>
                <HangarSignInButton path={pathBeforeRedirect} />
              </>
            )}
            <Typography variant="subtitle2" sx={{ mt: '15px' }}>
              Software Version{' '}
              {getEnvironmentVariable('REACT_APP_TOOLBOX_VERSION')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
