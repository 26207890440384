import { MicrosoftUser } from 'app/types/contexts/microsoft.types';
import { RoleEnum, RoleMapping } from 'roles/lists/groupsToAppRoles';

// function getPDDGroups() {
//   const developer = getEnvironmentVariable('REACT_APP_AD_GROUP_DEVELOPER');
//   const pdd = getEnvironmentVariable('REACT_APP_AD_GROUP_PDD');
//   const pddManager = getEnvironmentVariable('REACT_APP_AD_GROUP_PDDMANAGER');
//   return [developer, pdd, pddManager];
// }

export function isPddStaff(userObject: MicrosoftUser) {
  const pddStaffRoles: RoleEnum[] = ['designer'];
  const userRoles = userObject.roles;
  const filteredRoles = userRoles.filter((role: RoleMapping) =>
    pddStaffRoles.includes(role.role)
  );
  return filteredRoles.length > 0;
}

/**
 * Determine if a user is a PDD staff member or delegate.
 */
export function isDeveloper(userObject: MicrosoftUser) {
  const designerRoles: RoleEnum[] = ['developer'];
  const userRoles = userObject.roles;
  const filteredRoles = userRoles.filter((role: RoleMapping) =>
    designerRoles.includes(role.role)
  );
  return filteredRoles.length > 0;
}

/**
 * Determine if a user is a PDD staff member or delegate.
 */
export function isDesigner(userObject: MicrosoftUser) {
  const designerRoles: RoleEnum[] = ['developer', 'admin', 'designer'];
  const userRoles = userObject.roles;
  const filteredRoles = userRoles.filter((role: RoleMapping) =>
    designerRoles.includes(role.role)
  );
  return filteredRoles.length > 0;
}

/**
 * Determine if a user is a PDD staff member or delegate.
 */
export function isManager(userObject: MicrosoftUser) {
  const designerRoles: RoleEnum[] = ['developer', 'admin'];
  const userRoles = userObject.roles;
  const filteredRoles = userRoles.filter((role: RoleMapping) =>
    designerRoles.includes(role.role)
  );
  return filteredRoles.length > 0;
}

/**
 * Determine if a user is a PDD staff member or delegate.
 */
export function isPDDManager(userObject: MicrosoftUser) {
  const designerRoles: string[] = ['LCMS_PDDManager'];
  const userRoles = userObject.roles;
  const filteredRoles = userRoles.filter((role: RoleMapping) =>
    designerRoles.includes(role.displayName)
  );
  return filteredRoles.length > 0;
}
