/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_DEFAULT_PATH } from 'config';

// ================================|| ROUTER ||================================ //

/**
 * The purpose of this component is to direct the application to the correct page
 * after authentication to both Microsoft and the Hangar systems.
 * @return {React.ReactHTMLElement}
 */
const AuthRouter = () => {
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login'
    ) {
      setRedirected(true);
      navigate(APP_DEFAULT_PATH, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default AuthRouter;
