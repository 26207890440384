import { createSlice } from '@reduxjs/toolkit';

// project import
// import axios from 'utils/axios';
// import { dispatch } from 'store';

const initialState = {
  course: null,
  error: false
};

// ==============================|| SLICE - MENU ||============================== //

const course = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setActiveCourse(state, action) {
      state.course = action.payload.course;
    }
  }
});

export default course.reducer;

export const { setActiveCourse } = course.actions;
