// types
import {
  DefaultConfigProps,
  MenuOrientation,
  ThemeDirection,
  ThemeMode
} from 'types/mantis/config';

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = '/dashboard';
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;

// ==============================|| CUSTOM CONFIG  ||============================== //

/**
 * Exclude the page title in the layout where route contains the following:
 */
export const noBreadcrumb = ['/dashboard', '/editor', '/course/', '/document/'];
export const noTitle = ['/dashboard', '/editor', '/course/', '/document/'];

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  // fontFamily: `'Public Sans', sans-serif`,
  // fontFamily: `'Lato', sans-serif`, // Used to match Launchpad's font
  fontFamily: `'Inter', sans-serif`,
  i18n: 'en',
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'default',
  themeDirection: ThemeDirection.LTR,
  showLayout: true
};

export default config;
