function getCurrentAppEnvironment() {
  return process.env.REACT_APP_ENV;
}

export function getEnvironmentVariable(name: string): string {
  const newEnv = process.env[`${name}`];
  if (newEnv) {
    // console.log(`Environment variable ${name} retreived as ${newEnv}.`);
    return newEnv;
  } else {
    console.error(`Environment variable ${name} not set correctly.`);
    return '';
  }
}

/**
 * Get the current application environment
 * and translate it to an expected code value
 */
export function getCurrentAppEnvironmentCode() {
  const env = getCurrentAppEnvironment();
  switch (env) {
    case 'staging':
    case 'preprod':
      return 'preprod';
    case 'production':
    case 'prod':
      return 'prod';
    case 'development':
    case 'dev':
      return 'dev';
    default:
      return 'local';
  }
}

export function getCurrentServerEnvironmentCode() {
  const env = getCurrentAppEnvironment();
  switch (env) {
    case 'staging':
    case 'preprod':
      return 'preprod';
    case 'production':
    case 'prod':
      return 'prod';
    case 'development':
    case 'dev':
    case 'local':
      return 'dev';
    default:
      return 'dev';
  }
}
