/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import {
  Box,
  FormControl,
  Chip,
  InputLabel,
  MenuItem,
  useMediaQuery,
  Stack,
  Typography,
  Select
} from '@mui/material';
import IconButton from 'components/@mantis/material-extended/IconButton';

// project import
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
import Notification from './Notification';
// import MobileSection from './MobileSection';
// import MegaMenuSection from './MegaMenuSection';

import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// types
import { MenuOrientation } from 'types/mantis/config';
import { RoleMapping, groupsToAppRoles } from 'roles/lists/groupsToAppRoles';
import { useEffect, useState } from 'react';
import { setLocalStorageValue } from 'utils/storage/localStorage';
import { isDeveloper } from 'roles/departments/pdd';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation, onChangeShowLayout } = useConfig();
  const { user } = useAuth();

  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [tempRole, setTempRole] = useState('');

  const [roles, setRoles] = useState([
    { name: 'Default Role' },
    ...groupsToAppRoles
  ]);

  useEffect(() => {
    const tempRole = localStorage.getItem('toolbox-impersonate-role');
    if (tempRole) {
      setTempRole(tempRole);
    } else {
      setTempRole('Default Role');
    }
  }, [user]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}

      {/* {!downLG && <Search />} */}
      {(isDeveloper(user) || user.isImpersonating) && (
        // <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <FormControl fullWidth sx={{ pl: '20px' }}>
          {/* <InputLabel id="demo-simple-select-label">
            Developer Impersonate Role
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tempRole}
            onChange={(e: any) => {
              setTempRole(e.target.value);
              if (e.target.value === 'Default Role') {
                localStorage.removeItem('toolbox-impersonate-role');
              } else {
                setLocalStorageValue(
                  'toolbox-impersonate-role',
                  e.target.value
                );
              }
              window.location.reload();
            }}
          >
            {roles.map((e, i) => {
              return (
                <MenuItem key={i} value={e.name}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        // </Stack>
      )}

      {!matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* {!downLG && megaMenu}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      {/* <IconButton
        color="secondary"
        variant="light"
        // sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        // aria-label="hide menu bars"
        // ref={anchorRef}
        // aria-controls={open ? 'profile-grow' : undefined}
        // aria-haspopup="true"
        onClick={() => onChangeShowLayout(false)}
      >
        <FullscreenOutlined />
      </IconButton> */}
      {user.roles.map((e: RoleMapping) => {
        return (
          <Chip
            key={`role-${e.name}`}
            color="primary"
            label={`${e.name}`}
            size="small"
            style={{ borderRadius: '20px', fontSize: '10px' }}
          />
        );
      })}

      <Profile />
      {/* {downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
