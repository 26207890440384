import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/@mantis/Loadable';
import AuthGuard from 'routes/guard/AuthGuard';
import AuthRouter from 'pages/auth/AuthRouter';
import EditorLayout from 'layout/EditorLayout';

// Pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')));
const CoursesPage = Loadable(lazy(() => import('pages/courses')));
const CoursePage = Loadable(lazy(() => import('pages/course')));
const DocumentPage = Loadable(lazy(() => import('pages/document')));
const EditorPage = Loadable(lazy(() => import('pages/editor')));

// Maintenance
const Error404 = Loadable(lazy(() => import('pages/_maintenance/404')));
const Error500 = Loadable(lazy(() => import('pages/_maintenance/500')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <>
            <AuthRouter />
            <MainLayout />
          </>
        </AuthGuard>
      ),
      children: [
        {
          path: 'login',
          element: <></>
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        // Courseware
        {
          path: 'courses',
          element: <CoursesPage />
        },
        {
          path: 'course/:id',
          element: <CoursePage />
        },
        {
          path: 'document/:documentId',
          element: <DocumentPage />
        }
      ]
    },
    {
      path: '/editor',
      element: (
        <AuthGuard>
          <EditorLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <EditorPage />
        },
        {
          path: ':documentId',
          element: <EditorPage />
        }
      ]
    },
    /**
     * @Error404
     * If any other pages are navigated to show a 404 (not found).
     */
    {
      path: '*',
      element: <Error404 />
    },
    /**
     * @Error500
     * If a server error / disconnection occurs, navigate to the server error page.
     */
    {
      path: 'error',
      element: <Error500 />
    }
  ]
};

export default MainRoutes;
