/**
 * Local Storage Methods
 * No @imports required as this is a native JavaScript module.
 * ...
 * The localStorage read-only property of the window interface allows you to access
 * a Storage object for the Document's origin; the stored data is saved across browser sessions.
 * ...
 * Docs: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */

/**
 * Set localStorage value using key value pair
 */
export function setLocalStorageValue(key: string, value: any) {
  return localStorage.setItem(key, value);
}

/**
 * Get localStorage value by key
 */
export function getLocalStorageValue(key: string) {
  const value = localStorage.getItem(key);
  return value;
}

/**
 * Remove a localStorage value by key
 */
export function removeLocalStorageValue(key: string) {
  return localStorage.removeItem(key);
}

/**
 * Clear all localStorage values in the browser
 */
export function clearLocalStorage() {
  return localStorage.clear();
}

/**
 * Set localStorage with JSON input
 * This converts the object to a string before storing it.
 */
export function setLocalStorageJSON(key: string, objectValue: object) {
  localStorage.setItem(key, JSON.stringify(objectValue));
}

/**
 * Get a stringified JSON object from localStorage and convert it back to JSON.
 */
export function getLocalStorageJSON(key: string) {
  const storageItem = localStorage.getItem(key);
  if (storageItem) {
    const jsonObject = JSON.parse(storageItem);
    return jsonObject;
  } else return null;
}
