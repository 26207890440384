import { ReactNode } from 'react';

// material-ui
import { Box, Grid } from '@mui/material';

// project import
// import AuthFooter from 'components/cards/AuthFooter';
// import Logo from 'components/logo';
import AuthCard from './AuthCard';

// assets
import AuthBackground from 'assets/images/auth/AuthBackground';

interface Props {
  children: ReactNode;
}

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }: Props) => (
  <Box sx={{ minHeight: '100vh' }}>
    <AuthBackground />
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: {
          xs: 'calc(100vh - 210px)',
          sm: 'calc(100vh - 134px)',
          md: 'calc(100vh - 112px)'
        }
      }}
    >
      <Grid item>
        <AuthCard>{children}</AuthCard>
      </Grid>
    </Grid>
  </Box>
);

export default AuthWrapper;
