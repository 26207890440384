/**
 * Auth Function Endpoint
 */

/**
 * Get
 */
export function getAuthEndpoint() {
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  return `${baseUrl}/api/auth`;
}
