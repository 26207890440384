import { createSlice } from '@reduxjs/toolkit';

// project import
// import axios from 'utils/axios';
// import { dispatch } from 'store';

const initialState = {
  topic: null,
  error: false
};

// ==============================|| SLICE - MENU ||============================== //

const topic = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    setActiveTopic(state, action) {
      state.topic = action.payload.topic;
    }
  }
});

export default topic.reducer;

export const { setActiveTopic } = topic.actions;
