// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/@mantis/Locales';
import ScrollTop from 'components/@mantis/ScrollTop';
import Snackbar from 'components/@mantis/material-extended/Snackbar';
import Notistack from 'components/@third-party/Notistack';

// Auth Contexts
import MicrosoftProvider from 'contexts/MicrosoftContext';
import HangarProvider from 'contexts/HangarContext';

// date library adapter
import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';

import { MathJaxContext } from 'better-react-mathjax';
// MathJaxContext

const config = {
  tex2jax: {
    packages: { '[+]': ['html'] },
    processEscapes: true,
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)']
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]']
    ]
  },
  loader: {
    loader: { load: ['[tex]/html'] }
  }
};

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

interface AppProps {
  pca: any;
}

const App = ({ pca }: AppProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          {/* AuthProvider added for MSAL */}
          <MicrosoftProvider pca={pca}>
            {/* AuthProvider for the Hangar (Aviation Australia API) */}
            <HangarProvider>
              <MathJaxContext
                config={config}
                // src={'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js'}
              >
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </MathJaxContext>
            </HangarProvider>
          </MicrosoftProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  </LocalizationProvider>
);

export default App;
