import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import { WindowsOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { loginRequest } from 'msal';

type LoginType = 'popup' | 'redirect';

export const MicrosoftSignInButton = () => {
  const { instance } = useMsal();

  /**
   * Handle Login
   * @param {*} loginType
   */
  const handleLogin = (loginType: LoginType) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest);
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<WindowsOutlined />}
        onClick={() => handleLogin('redirect')}
        color="primary"
        size="large"
        sx={{
          padding: '10px 30px',
          fontSize: '14pt',
          fontWeight: 700
        }}
      >
        Microsoft SSO Login
      </Button>
    </Box>
  );
};
